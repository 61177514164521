/* MODAL */
.modal-content {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    outline: 0 none;
    position: relative;
}

.modal-dialog {
    z-index: 2200;
}

.modal-body {
    padding: 20px 30px 30px 30px;
}

.inmodal .modal-body {
    background: #f8fafb;
}

.inmodal .modal-header {
    padding: 30px 15px;
    text-align: center;
}

.animated.modal.fade .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.inmodal .modal-title {
    font-size: 26px;
}

.inmodal .modal-icon {
    font-size: 84px;
    color: #e2e3e3;
}

.modal-footer {
    margin-top: 0;
}

body.modal-open {
    padding-right: inherit !important;
}

body.modal-open .wrapper-content.animated {
    -webkit-animation: none;
}

body.modal-open .animated {
    animation-fill-mode: initial;
    z-index: inherit;
}

.payment-card {
    background: #ffffff;
    padding: 20px;
    margin-bottom: 25px;
    border: 1px solid #e7eaec;
}

.payment-icon-big {
    font-size: 60px;
    color: #d1dade;
}

.payments-method.panel-group .panel + .panel {
    margin-top: -1px;
}

.payments-method .panel-heading {
    padding: 15px;
}

.payments-method .panel {
    border-radius: 0;
}

.payments-method .panel-heading h5 {
    margin-bottom: 5px;
}

.payments-method .panel-heading i {
    font-size: 26px;
}

.fb {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fb-machineicon {
    background: url('../../img/machine/machine_orange.png') no-repeat center;
    width: 110px;
    height: 85px;
}

.fb-machineicon:hover {
    background: url('../../img/machine/machine_white.png') no-repeat center;
}

.fb-machineicongrey {
    background: url('../../img/machine/machine_grey.png') no-repeat center;
    width: 110px;
    height: 85px;
}

.fb-armicon {
    background: url('../../img/arm/arm_orange.png') no-repeat center;
    width: 110px;
    height: 85px;
}

.fb-armicon:hover {
    background: url('../../img/arm/arm_white.png') no-repeat center;
}

.fb-armicongrey {
    background: url('../../img/arm/arm_grey.png') no-repeat center;
    width: 110px;
    height: 85px;
}

.btnmachine {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btnarm {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btnarm.disabled {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.btnmachine.disabled {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.file-boxcustom{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

/* Tabs */
.tabs-container .panel-body {
    background: #fff;
    border: 1px solid #e7eaec;
    border-radius: 2px;
    padding: 20px;
    position: relative;
}
.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
    border: 1px solid #e7eaec;
    border-bottom-color: transparent;
    background-color: #fff;
}
.tabs-container .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
}
.tabs-container .tab-pane .panel-body {
    border-top: none;
}
.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
    border: 1px solid #e7eaec;
    border-bottom-color: transparent;
}
.tabs-container .nav-tabs {
    border-bottom: 1px solid #e7eaec;
}
.tabs-container .tab-pane .panel-body {
    border-top: none;
}
.tabs-container .tabs-left .tab-pane .panel-body,
.tabs-container .tabs-right .tab-pane .panel-body {
    border-top: 1px solid #e7eaec;
}
.tabs-container .nav-tabs > li a:hover {
    background: transparent;
    border-color: transparent;
}
.tabs-container .tabs-below > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs,
.tabs-container .tabs-left > .nav-tabs {
    border-bottom: 0;
}
.tabs-container .tabs-left .panel-body {
    position: static;
}
.tabs-container .tabs-left > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs {
    width: 20%;
}
.tabs-container .tabs-left .panel-body {
    width: 80%;
    margin-left: 20%;
}
.tabs-container .tabs-right .panel-body {
    width: 80%;
    margin-right: 20%;
}
.tabs-container .tab-content > .tab-pane,
.tabs-container .pill-content > .pill-pane {
    display: none;
}
.tabs-container .tab-content > .active,
.tabs-container .pill-content > .active {
    display: block;
}
.tabs-container .tabs-below > .nav-tabs {
    border-top: 1px solid #e7eaec;
}
.tabs-container .tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0;
}
.tabs-container .tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}
.tabs-container .tabs-below > .nav-tabs > li > a:hover,
.tabs-container .tabs-below > .nav-tabs > li > a:focus {
    border-top-color: #e7eaec;
    border-bottom-color: transparent;
}
.tabs-container .tabs-left > .nav-tabs > li,
.tabs-container .tabs-right > .nav-tabs > li {
    float: none;
}
.tabs-container .tabs-left > .nav-tabs > li > a,
.tabs-container .tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
}
.tabs-container .tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
}
.tabs-container .tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}
.tabs-container .tabs-left > .nav-tabs .active > a,
.tabs-container .tabs-left > .nav-tabs .active > a:hover,
.tabs-container .tabs-left > .nav-tabs .active > a:focus {
    border-color: #e7eaec transparent #e7eaec #e7eaec;
    *border-right-color: #ffffff;
}
.tabs-container .tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
}
.tabs-container .tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}
.tabs-container .tabs-right > .nav-tabs .active > a,
.tabs-container .tabs-right > .nav-tabs .active > a:hover,
.tabs-container .tabs-right > .nav-tabs .active > a:focus {
    border-color: #e7eaec #e7eaec #e7eaec transparent;
    *border-left-color: #ffffff;
    z-index: 1;
}
@media (max-width: 930px) {
    .tabs-container .nav-tabs > li {
        float: none !important;
    }
    .tabs-container .nav-tabs > li.active > a {
        border-bottom: 1px solid #e7eaec !important;
        margin: 0;
    }
}